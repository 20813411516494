






















































import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import LabelBase from '@/components/atoms/LabelBase.vue'
import StudentBottomPanel from '@/components/organisms/StudentBottomPanel.vue'
import DrillWebApi from '@/mixins/drills/WebApi'
import LessonStudent from '@/mixins/action-cable/LessonStudent/index'
import ClassModeChangeAble from '@/mixins/student/ClassModeChangeAble'
import VueRouter from 'vue-router'

Component.registerHooks(['beforeRouteUpdate'])

@Component({
  components: {
    SidebarSwitcher,
    ColoredBox,
    ButtonBase,
    LabelBase,
    StudentBottomPanel,
  },
})
export default class CurriculumSUnitsClassCategory extends Mixins(DrillWebApi, LessonStudent, ClassModeChangeAble) {
  public classMode(): string {
    return Vue.prototype.$cookies.get('dataGdls').classMode
  }

  private subjectCode = Vue.prototype.$cookies.get('dataGdls').subjectCode

  private curriculumSUnitName = ''
  private lessonName = ''
  private classPolicy: { [key: string]: boolean } = {}
  private history: { [key: string]: any } = {}

  private noticeCurrentPageText = '授業内容選択中'

  private noticePageTitle = ''

  private colorType = 'gray'

  protected get lessonId() {
    return this.$store.getters['lessonActionCable/lessonId']
  }

  @Watch('lessonId')
  private async lessonIdChanged(): Promise<void> {
    await this.loadDatas()
  }

  public async mounted(): Promise<void> {
    await this.checkClassModeAndSideMenuMode()

    await this.loadDatas()

    this.noticeGroup()

    await this.getExistSupporterCallHistory()
  }

  public beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    console.info('-- SelectSubject beforeRouteUpdate')

    this.noticeGroup()

    next()
  }

  private async noticeGroup() {
    // このページでは固定文言のためページ遷移時またはbeforeRouteUpdate時に現在ページを伝える
    this.noticeOrStackCurrentPage({ currentPage: this.noticeCurrentPageText, pageTitle: this.noticePageTitle })

    // router.pushではsetTimeoutがクリアされないためセットする前にクリアして初期化しておく
    const setTimeoutIdQueueSize = await this.$store.dispatch('queue/size', 'setTimeoutIdQueue')
    for (let i = 0; i < setTimeoutIdQueueSize; i++) {
      const setTimeoutId = await this.$store.dispatch('queue/dequeue', 'setTimeoutIdQueue')
      clearTimeout(setTimeoutId)
    }

    // 5分、10分経過後にポイントを付与する
    this.noticeOrStackCurrentPageDelayed(10, { point: 5 })
    this.noticeOrStackCurrentPageDelayed(20, { point: 10 })
  }

  private async loadDatas(): Promise<void> {
    // TODO: 授業中の場合は未実装
    await Vue.prototype.$http.httpWithToken
      .get('/curriculum_s_unit_categories', {
        params: {
          curriculumSUnitId: Number(this.$route.params.curriculumSUnitId),
          lessonId: this.lessonId,
          classMode: this.classMode(),
          subjectCode: this.subjectCode,
        },
      })
      .then((res: any) => {
        this.curriculumSUnitName = res.data.curriculumSUnitName
        this.lessonName = res.data.lessonName
        this.classPolicy = res.data.classPolicy
        this.history = res.data.history
      })
  }

  private get introductionUrl(): string {
    return `/student/curriculum-s-units/${this.$route.params.curriculumSUnitId}/introduction-video`
  }

  private get unitListUrl(): string {
    if (this.classMode() == this.CLASS_MODE.SEMINAR) {
      return '/student/seminar-unit-selection'
    }
    return '/student/curriculum-s-units'
  }

  private get rectangleStyleSet(): { [key: string]: string } {
    let listSize = 0
    if (this.classPolicy['EZ']) listSize += 1
    if (this.classPolicy['RK']) listSize += 1
    if (this.classPolicy['EN']) listSize += 1
    let height = '0'
    if (listSize === 3) {
      height = '180px'
    } else if (listSize === 2) {
      height = '90px'
    }
    return {
      '--rectangle-height': height,
    }
  }

  private async startDrill(classCategoryCode: string): Promise<any> {
    Vue.prototype.$loading.start()
    const startDrill = await this.startDrillApi({
      lessonId: this.lessonId,
      classModeCode: this.classMode(),
      classCategoryCode: classCategoryCode,
      curriculumSUnitId: Number(this.$route.params.curriculumSUnitId),
    })

    switch (startDrill.pageType) {
      case 'question':
        // ドリル（確認テスト）開始APIの結果を受けて問題ページへ遷移する
        // 途中で抜けていた場合はそのpageへ遷移
        this.$router.push({
          name: 'drill',
          query: {
            resultDrillId: String(startDrill.resultDrillId),
            pageType: startDrill.pageType,
            page: String(startDrill.page || ''),
            // 以下は引き続き演習問題で利用するのに必要なため引き回す
            curriculumSUnitId: this.$route.params.curriculumSUnitId, // 小項目ID
            classCategoryCode: classCategoryCode, // 授業種別
          },
        })
        break
      case 'result_all':
        // ドリル（確認テスト）開始APIの結果を受けて結果ページ遷移する
        // 理解度テストで1度解いている場合に遷移
        this.$router.push({
          name: 'drillResult',
          query: {
            resultDrillId: String(startDrill.resultDrillId),
            pageType: startDrill.pageType,
            page: String(startDrill.page || ''),
            // 以下は引き続き演習問題で利用するのに必要なため引き回す
            curriculumSUnitId: this.$route.params.curriculumSUnitId, // 小項目ID
            classCategoryCode: classCategoryCode, // 授業種別
          },
        })
        break
    }
    Vue.prototype.$loading.complete()
  }

  private callSupporterClicked() {
    if (this.colorType === 'gray') {
      this.colorType = 'darkgray'
    }
    this.callSupporter(true)
  }

  private async getExistSupporterCallHistory() {
    if (this.lessonId) {
      try {
        const res = await Vue.prototype.$http.httpWithToken.get(`/lessons/${this.lessonId}/supporterCallHistory`)
        this.colorType = res.data.existSupporterCallHistory ? 'darkgray' : 'gray'
      } catch (e) {
        console.error(`サポーターを呼ぶボタンの履歴取得でエラーが発生しました。 ${e}`)
        throw e
      }
    }
  }

  @Watch('lessonId')
  onLessonIdChanged() {
    this.getExistSupporterCallHistory()
  }
}
